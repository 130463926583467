<template>
  <v-container>
    <div class="hero">
      <div class="hero-overlay"></div>
      <div class="hero-container">
        <div class="hero__text">
          <p class="hero__text__title"><span class="hero__text__title-a">A</span>LGUA<span class="hero__text__title-z">Z</span>AS</p>
          <p class="hero__text__subtitle">un gran pueblo de la <span class="hero__text__subtitle-a">A</span> a la <span class="hero__text__subtitle-z">Z</span></p>
        </div>
        <div class="hero__form">
          <input type="text" class="hero__form-input" v-model="searchText" placeholder="Buscar comercio" />
          <select class="hero__form-select" v-model="selectedCategory">
            <option disabled value="null">Categoría</option>
            <option value="all">Todas</option>
            <option v-for="category in categories" :key="category.idCategoria" :value="category.idCategoria">
              {{ category.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- <div class="categories">
      <div v-for="category in categories" :key="category.idCategoria">
        <img :src="'https://app-api.acealguazas.es/public/foto/'+category.foto" height="30" />
        {{ category.nombre }}
      </div>
    </div> -->
    <v-row class="cards" justify="center" align="stretch" v-if="comerces.length > 0">
      <v-col cols="12" sm="6" md="4" lg="4" xl="3" v-for="comerce in comerces" :key="comerce.idComercio" style="flex-grow: 0;" class="d-flex justify-center"> 
          <comerce-card :comerce="comerce"></comerce-card>
      </v-col>
    </v-row>
    <div class="mt-6" v-else>
      <h3>¡Ooops! Parece que no hemos encontrado comercios que coincidan con tu búsqueda.</h3>
    </div>
  </v-container>
</template>

<script>
  import ComerceCard from '@/components/ComerceCard.vue'

  export default {
    name: 'Home',
    components: {
      ComerceCard
    },
    data: () => ({
      searchText: null,
      selectedCategory: null
    }),
    computed: {
      comerces() {
        if (!this.searchText || this.searchText == '') {
          if (this.selectedCategory == "all" || this.selectedCategory == null) {
            return this.$store.getters.getComercios;
          } else {
            return this.$store.getters.getComercios.filter(shop => shop.categorias.some(cat => cat.idCategoria == this.selectedCategory))
          }
        } else if (this.selectedCategory == "all" || this.selectedCategory == null) {
          console.log('todas las categorias');
          return this.$store.getters.getComercios.filter(shop => shop.nombre.toLowerCase().includes(this.searchText.toLowerCase()) || shop.descripcion.toLowerCase().includes(this.searchText.toLowerCase()) || shop.descripcion_corta.toLowerCase().includes(this.searchText.toLowerCase()))
        } else {
          console.log('categoria '+this.selectedCategory);
          return this.$store.getters.getComercios.filter(shop => ((shop.nombre.toLowerCase().includes(this.searchText.toLowerCase()) || shop.descripcion.toLowerCase().includes(this.searchText.toLowerCase()) || shop.descripcion_corta.toLowerCase().includes(this.searchText.toLowerCase())) && shop.categorias.some(cat => cat.idCategoria == this.selectedCategory)))
        }
      },
      categories() {
        return this.$store.getters.getCategorias;
      }
    }
  }
</script>

<style lang="scss">
.container {
  padding: 0 !important;
}
.hero {
  background-image: url('../assets/hero.jpeg');
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  min-height: 300px;
  position: relative;
  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
  }
  &-container {
    z-index: 5;
    margin: auto;
  }
  &__text {
    color: white;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
    &__title {
      margin: 0 !important;
      font-size: 50px;
      font-weight: bold;
      line-height: 40px;
      &-a {
        color: #e0433d;
      }
      &-z {
        color: #9bba43;
      }
      @media (max-width: 768px) {
        font-size: 46px;
      }
      @media (max-width: 480px) {
        font-size: 42px;
      }
    }
    &__subtitle {
      margin: 0;
      font-size: 32px;
      font-weight: 600;
      &-a {
        font-weight: bold;
        font-size: 36px;
        color: #e0433d;
      }
      &-z {
        font-weight: bold;
        font-size: 36px;
        color: #9bba43;
      }
      @media (max-width: 768px) {
        font-size: 28px;
        &-a, &-z {
          font-size: 32px;
        }
      }
      @media (max-width: 480px) {
        font-size: 22px;
        &-a, &-z {
          font-size: 26px;
        }
      }
    }
  }
  &__form {
    z-index: 4;
    display: flex;
    margin: auto;
    &-input {
      margin: auto;
      margin-right: 20px;
      background-color: white;
      border-radius: 1px;
      border: 1px solid #eee;
      text-align: left;
      padding: 10px;
      max-width: 500px;
      width: 60vw;
    }
    &-select {
      background-color: white;
      width: 30vw;
      max-width: 200px;
      color: gray;
      padding: 8px;
      border-radius: 1px;
      border: 1px solid #eee;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 99%;
      background-position-y: 11px;
    }
    @media (max-width: 480px) {
      display: block;
      &-input, &-select {
        min-width: 80vw;
        margin: 0;
      }
      &-input {
        margin-bottom: 20px;
      }
    }
  }
}
.cards {
  margin: 20px !important;
}
</style>
