<template>
  <v-card
    class="comerce-card card-outter"
    @click="goToComerce(comerce.idComercio)"
  >
    <v-img
      :src="'https://app-api.acealguazas.es/public/foto/'+comerce.fotoListado"
      height="200px"
    ></v-img>
    <hr>
    <div class="comerce-card__categories">
      <span class="comerce-card__categories-category" v-for="category in comerce.categorias" :key="category.idCategoria">{{ category.nombre}}</span>
    </div>
    <v-card-title>
      {{ comerce.nombre }}
    </v-card-title>

    <v-card-subtitle>
      {{ comerce.descripcion_corta }}
    </v-card-subtitle>
    
    <v-card-actions>
      <v-btn
        icon
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'ComerceCard',
    props: ['comerce'],
    methods: {
      goToComerce(id) {
        this.$router.push({ path: `/comercio/${id}` })
      }
    }
  }
</script>

<style lang="scss">
.comerce-card {
  width: 100%;
  cursor: pointer;
  transition: all ease-in-out 300ms !important;
  transform: scale(1);
  &:hover {
    transform: scale(1.05);
  }
  .v-image__image--cover {
    background-size: contain;
  }
  hr {
    border: 1px solid #eee;
  }
  &__categories {
    text-align: left;
    margin-top: 20px;
    margin-left: 16px;
    margin-right: 16px;
    font-size: 14px;
    &-category {
      margin-right: 10px;
      border-radius: 20px;
      color: #777;
      padding: 6px 10px;
      background-color: rgba(#f48727, 0.25);
    }
  }
  .v-card__title {
    text-align: left;
    word-break: break-word;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .v-card__subtitle {
    text-align: left;
  }
  .v-card__actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    .v-btn--icon {
      margin-left: auto;
      color: #f48727 !important;
    }
  }
}
  .card-outter {
    position: relative;
    padding-bottom: 35px;
  }
</style>
